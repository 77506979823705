<template>
  <div class="setBox">
    <div class="topBackImg">
      <div class="goBack" @click="goBack()">
        <img src="../../image/my/g.png" alt="" />
      </div>
      <div class="information">
        <div class="hear"><img :src="user.headImg" alt=""></div>
        <div class="nameSchool">
          <div class="name">{{user.nickName}}</div>
          <div class="school">{{user.schoolName}}</div>
        </div>
      </div>
      <div class="signature">
        {{user.desc}}
      </div>
    </div>
    <div class="numBox">
      <div class="numSize">
        <div class="numStyle">{{userState.followNum}}</div>
        <div class="sizeStyle">关注</div>
      </div>
      <div class="numSize">
        <div class="numStyle">{{userState.trendsLikeNum + userState.trendsCollectionNum}}</div>
        <div class="sizeStyle">赞和收藏</div>
      </div>
      <div class="numSize">
        <div class="numStyle">{{userState.fansNum}}</div>
        <div class="sizeStyle">粉丝</div>
      </div>
      <div class="numSize">
        <div class="numStyle">{{userState.friendNum}}</div>
        <div class="sizeStyle">朋友</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="navBox">
      <div
        v-for="item in navList"
        :key="item.id"
        :class="item.id === navActive ? 'activeStyle' : ''"
        @click="navEvent(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="container">
      <div class="contentBox" v-for="item in trends" :key="item.id">
        <div class="contentImg" @click="goDetail(item.id)">
          <img :src="item" alt="" v-for="item in JSON.parse(item.imgs).slice(0,1)" :key="item" />
          <div class="contentSize">{{item.content}}</div>
          <div class="likeNum" @click.stop="likeEvent()">
            <img src="../../image/xmrx/d.png" alt="" />
           {{item.likeNum}}赞
          </div>
        </div>
      </div>
      <!-- <div class="contentBox">
        <div class="contentImg" @click="goDetail()">
          <img src="../../image/2.png" alt="" />
          <div class="contentSize">开启时髦异域之旅，跟着ETRO去旅行</div>
          <div class="likeNum" @click.stop="likeEvent()">
            <img src="../../image/xmrx/d1.png" alt="" />
            2339赞
          </div>
        </div>
      </div> -->
      <!-- <div class="contentBox">
        <div class="contentImg">
          <img src="../../image/3.png" alt="" />
          <div class="contentSize">开启时髦异域之旅，跟着ETRO去旅行</div>
        </div>
      </div> -->
      <!-- <div class="contentBox">
        <div class="contentImg">
          <img src="../../image/4.png" alt="" />
          <div class="contentSize">开启时髦异域之旅，跟着ETRO去旅行</div>
        </div>
      </div> -->
      <!-- <div class="contentBox">
        <div class="contentImg">
          <img src="../../image/4.png" alt="" />
          <div class="contentSize">开启时髦异域之旅，跟着ETRO去旅行</div>
        </div>
      </div> -->
      <!-- <div class="contentBox">
        <div class="contentImg">
          <img src="../../image/6.png" alt="" />
          <div class="contentSize">开启时髦异域之旅，跟着ETRO去旅行</div>
        </div>
      </div> -->
    </div>
    <div class="fixed" @click="gosendPage()"><img src="../../image/xmrx/j.png" alt="" /></div>
  </div>
</template>

<script>
import {listTrends} from '@/api/trends'
import { userState } from "../../api/circle";
export default {
  data() {
    return {
      navList: [
        {
          id: 0,
          name: "动态",
        },
        {
          id: 1,
          name: "仅自己可见",
        },
      ],
      navActive: 0,
      search: {
        range: 0,
        userId:this.$store.state.user.id,
        pageNum: 1,
        pageSize: 10,
      },
      trends:[],
      userState:{},
      user:this.$route.query.user,
    };
  },
  mounted() {
    userState({userId:this.$store.state.user.id}).then(res=>{
      console.log(res,'动态信息');
      this.userState = res.data.data
    })
    this.listTrends()
    this.$forceUpdate()
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/myIndex" });
    },
    navEvent(item) {
      this.trends = []
      this.navActive = item.id;
      this.search.range = item.id;
      this.listTrends()
    },
     listTrends() {
      listTrends(this.search).then(res => {

        if (res.data.code === 0) {
            if (res.data.data.items !== null) {
              this.trends = this.trends.concat(res.data.data.items);
            }
            // if (this.trends.length >= res.data.data.total) {
            //   this.finished = true;
            // } else {
            //   this.finished = false;
            // }
        }
        
        // if (this.trends.length >= res.data.data.total) {
        
      })
    },
    // 动态详情
    goDetail(id) {
      this.$router.push({ path: "/trendsDetail" , query: {id: id}});
    },
    likeEvent() {
      console.log("点赞");
    },
    gosendPage(){
      this.$router.push({path:'/sendPage'})
    }
  },
};
</script>

<style scoped>
.topBackImg {
  width: 750px;
  height: 476px;
  background-image: url("../../image/my/back.png");
  background-size: 100% 100%;
}
.goBack img {
  width: 40px;
  height: 40px;
  margin-top: 30px;
  margin-left: 32px;
}
.information {
  display: flex;
  align-items: center;
  margin-top: 44px;
  margin-left: 32px;
}
.hear img{
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.nameSchool {
  margin-left: 24px;
}
.name {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
}
.school {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 8px;
}
.signature {
  width: 454px;
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.4);
  margin-left: 32px;
  margin-top: 24px;
  display: -webkit-box; /* Safari */
  -webkit-line-clamp: 1; /* Safari and Chrome */
  -webkit-box-orient: vertical; /* Safari and Chrome */
  overflow: hidden;
}
.numBox {
  height: 168px;
  width: 750px;
  background: #ffffff;
  margin-top: -106px;
  border-radius: 24px 24px 0px 0px;
  display: flex;

  align-items: center;
  justify-content: space-around;
}
.numSize {
  text-align: center;
}
.numStyle {
  font-size: 40px;
  font-weight: 400;
  color: #0b1526;
}
.sizeStyle {
  font-weight: 400;
  color: #97a4b4;
  font-size: 24px;
  margin-top: 8px;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.navBox {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 30px;
}
.navBox div {
  margin-left: 32px;
  margin-right: 16px;
}
.activeStyle {
  font-weight: 600;
  font-size: 32px;
  color: #0b1526;
}
.container {
  /* width: 686px; */
  /* 整个窗口的高 */
  /* flex: 1; */
  /* 多列显示 */
  columns: 2;
  /* overflow: auto; */
  padding-left: 32px;
  padding-right: 86px;
  box-sizing: border-box;
  margin-top: 32px;
  margin-bottom: 56px;
  /* margin-left: 32px; */
}
.contentBox {
  width: 332px;
}
.contentImg {
  /* width: 332px; */
  /* 不折列 */
  break-inside: avoid;
  position: relative;
}
.contentImg img {
  width: 100%;
  border-radius: 16px;
}
.contentSize {
  width: 332px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-bottom: 44px;
  display: -webkit-box; /* Safari */
  -webkit-line-clamp: 2; /* Safari and Chrome */
  -webkit-box-orient: vertical; /* Safari and Chrome */
  overflow: hidden;
}
.likeNum {
  position: absolute;
  bottom: 106px;
  right: 16px;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}
.likeNum img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.fixed {
  position: fixed;
  bottom: 128px;
  right: 46px;
}
.fixed img {
  width: 120px;
  height: 120px;
}
</style>
